import { rest } from "msw";

export const handlers = [
  rest.get(
    "/api/v1/admin-portal-shared-bff/user-metadata",
    (_req, res, ctx) => {
      return res(
        ctx.status(200),
        ctx.json({
          authorization: {
            groups: [""],
            scopes: [""],
          },
          SUPPORTED_COUNTRIES: [""],
          userId: "",
        }),
      );
    },
  ),
];
