import MessageMap from "../i18n.d";

const enUS: MessageMap = {
  RESTRICTION_ATTRIBUTES: {
    TYPE: "Type",
    KEY: "Key",
    CASHBACK: "Cashback",
    ONBOARDING: "Onboarding",
    LAST_EDIT: "Last Edit",
    EDIT_BY: "Last Edit By",
    EDIT: "Edit",
    TITLE: "Restriction List",
    ANTIFRAUD: "Antifraud",
    COUNTRY_OF_RESTRICTION: "Country of Restriction",
    COUNTRY: "Country",
    NEW_RESTRICTION: "New restriction",
    PLACEHOLDER_SEARCH: "Search restriction key",
    ZE_COMPENSA: "Zé Compensa",
    CLUB_TADA: "Club TaDa",
    NEW_KEY_RESTRICTION: "New Key Restriction",
    RESTRICTION_DETAILS: "Restriction Details",
    KEY_TYPE: "Key type",
    KEY_TYPE_PLACEHOLDER: "Choose the type of restriction",
    KEY_TO_RESTRICT: "Key to restrict",
    KEY_TO_RESTRICT_PLACEHOLDER: `E.g: "restrict.user@gmail.com”`,
    KIND_OF_RESTRICTION: "Kind of restriction",
    RESTRICTION_REASON: "Restriction Reason",
    RESTRICTION_REASON_PLACEHOLDER: "Choose the restriction reason",
    CONFIRM_THE_UPDATE_FOR_RESTRICTION: "Confirm the update for restrictions",
    LOCKED: "Locked",
    UNLOCKED: "Unlocked",
    EDIT_RESTRICTION_KEY: "Edit a restriction key",
  },
  EDIT_RESTRICTION: {
    DETAILS_OF_RESTRICTION: "Restriction Details",
    TYPE_OF_KEY: "Key type",
    KEY_OF_RESTRICTION: "Restricted key",
    COUNTRY_OF_RESTRICTION: "Country of restriction",
    TYPE_OF_RESTRICTION: "Kind of restriction",
    REASON_OF_RESTRICTION: "Restriction Reason",
    UPDATE_RESTRICTION: "Update Restriction",
  },
  RESTRICTION_HISTORY: {
    TITLE: "Restriction historic",
    ONBOARDING_LOCKED: "Onboarding - Locked",
    ONBOARDING_UNLOCKED: "Onboarding - Unlocked",
    RESTRICTION_UPDATED: "Restriction modified",
    RESTRICTION_CREATED: "Restriction created",
  },
  RESTRICTION_TABLE: {
    DOCUMENT_TYPE: {
      HN: "RTN",
      BR: "CPF",
      SV: "NIT or DUI",
      PA: "RUC",
      BO: "NIT",
      PY: "RUC",
      PE: "DNI or RUC",
      EC: "RUC",
      DO: "Cédula",
      CO: "NIT",
      MX: "RFC",
      ZA: "TIN",
      AR: "DNI",
    },
    TYPE: {
      DOCUMENT: "Document ID",
      EMAIL: "Email",
      PHONE_NUMBER: "Phone number",
      DEVICE_ID: "Device",
    },
    LOADING: {
      TITLE: "Loading",
      MESSAGE: "We are preparing the restriction list for you.",
    },
    EMPTY: {
      TITLE: "Ops...",
      MESSAGE: "We didn't receive any data from this country.",
    },
    FILTERED: {
      TITLE: "No Restriction yet",
      MESSAGE: "The restriction will be displayed here.",
    },
  },
  BUTTONS: {
    EDIT: "Edit",
  },
  TEST: {
    TRANSLATE: "Translate",
    MISSING: "Only English option available",
  },
  SIDEMENU: {
    TITLE: "Home",
  },
  ACTIONS: {
    GOBACK: "Go back",
    BACK: "Back",
    CANCEL: "Cancel",
    CONFIRM: "Confirm",
    SAVE: "Save",
    SEARCH: "Search",
    APPLY: "Apply",
    COPY: "Copy",
    EDIT: "Edit",
    SELECT: {
      DEFAULT: "Select",
      ALL: "Select All",
      ONE_OR_MORE: "Select one or more",
    },
    REMOVE: "Remove",
    YES: "Yes",
    NO: "No",
    SHOW_DETAILS: "Show details",
    CREATE: "Create",
    CONTINUE: "Continue",
    PUBLISH: "Publish",
    TRY_AGAIN: "Try again",
    CONFIRM_RESTRICTION: "Confirm restriction",
  },
  COUNTRIES: {
    HN: "Honduras",
    SV: "El Salvador",
    PA: "Panama",
    BO: "Bolivia",
    DO: "Dominican Republic",
    PY: "Paraguay",
    PE: "Peru",
    EC: "Ecuador",
    ZA: "South Africa",
    CO: "Colombia",
    MX: "Mexico",
    AR: "Argentina",
    BR: "Brazil",
  },
  UNAUTHORIZED_PAGE: {
    TITLE: "Oops, access denied!",
    DESCRIPTION: "It seems you don't have permission to access this page.",
    ACTION: "Go back to the homepage",
  },
  PAGINATION: {
    QUANTITY_INDICATOR: "of",
    PAGE_SIZE_OPTIONS: "Items per page: {options}",
  },
  ERROR: {
    400: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
    401: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "Contact our support team to review your account settings.",
    },
    403: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "Contact our support team to review your account settings.",
    },
    404: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "We couldn't perform this action. Try again.",
    },
    500: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
    502: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
    503: {
      TITLE: "Oops, something went wrong!",
      MESSAGE: "An unexpected error ocurred. Please try again.",
    },
  },
  FIELDS: {
    OPTIONAL: "Optional",
    REQUIRED: {
      GENERIC: "Required field",
    },
    FORMAT: {
      GENERIC: "Invalid format",
    },
    VALIDATION: {
      DUPLICATED: "This key is already restricted",
    },
  },
  TOASTS: {
    RESTRICTION_UPDATED_SUCCESSFULLY: "Restriction Updated Successfully",
  },
  VERSION: {
    VERSION_APP: "Version",
    SERVER: "Server",
    RIGHTS_RESERVED: "©2023. Anheuser-Busch Inbev S.A. All rights reserved.",
  },
};

export default enUS;
