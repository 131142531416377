export interface IPaginationProps {
  page: number;
  pageSize: number;
  totalElements: number;
  totalPages?: number;
}

export interface IHistory {
  action: string;
  created_by?: string;
  current_data_state: { banned: boolean; services: string[] };
  reason: string;
  restricted_item: string;
  created_at?: string;
}

export interface IRestriction {
  banned: boolean;
  id: string;
  value: string;
  key: RestrictKeysEnum | undefined;
  services: Array<RestrictServicesEnum>;
  country_code?: string;
  updated_at?: string;
  created_at?: string;
  created_by?: string;
  history?: Array<IHistory>;
}

export enum RestrictKeysEnum {
  DOCUMENT = "DOCUMENT",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL = "EMAIL",
  DEVICE_ID = "DEVICE_ID",
}

export enum RestrictListKeyEnum {
  DOCUMENT = "DOCUMENT",
  PHONE_NUMBER = "PHONE_NUMBER",
  EMAIL = "EMAIL",
  DEVICE_ID = "DEVICE_ID",
}

export type RestrictServicesType =
  | "CLUBE_DO_ZE"
  | "COUPON"
  | "CASHBACK"
  | "ONBOARDING"
  | "CHECKOUT"
  | "PLATFORM";

export enum RestrictServicesEnum {
  CLUBE_DO_ZE = "clube-do-ze",
  COUPON = "coupon",
  CASHBACK = "cashback",
  ONBOARDING = "onboarding",
  CHECKOUT = "checkout",
  PLATFORM = "platform",
}

export interface INewRestrictionSchema
  extends Pick<IRestriction, "key" | "services" | "banned"> {
  created_by: string;
  reason: string;
  value: string;
}

export interface IEditRestrictionSchema
  extends Pick<INewRestrictionSchema, "key" | "value" | "reason" | "banned"> {
    history: Array<IHistory>;
  }

export interface IRestrictReasons {
  EN?: Record<string, string>;
  ES?: Record<string, string>;
  PT?: Record<string, string>;
}

export enum RestrictionErrorTypesEnum {
  RESTRICT_ALREADY_EXISTS = "RESTRICT_ALREADY_EXISTS",
  UNDEFINED = "UNDEFINED",
}

export type FormatMessageType = (params: { id: string }) => string;
