import { Error403, LoadingBuzz } from "@hexa-ui/components";
import { Suspense, lazy } from "react";
import { useIntl } from "react-intl";
import { Route, Routes, useNavigate } from "react-router-dom";
import Can, { Props as CanProps } from "./components/Can/Can";
import PERMISSION_GROUPS from "./config/permissions";
import APP_ROUTES from "./config/routes";

import BreadcrumbProvider from "./providers/BreadcrumbProvider/BreadcrumbProvider";

const RestrictionListPage = lazy(
  () => import("./pages/RestrictListPage/RestrictListPage")
);
const NewRestrictionPage = lazy(
  () => import("./pages/NewRestrictionPage/NewRestrictionPage")
);
const EditRestrictionPage = lazy(
  () => import("./pages/EditRestrictionPage/EditRestrictionPage")
);

interface RouteWithPermissionProps {
  children: React.ReactNode;
  fallback: CanProps["fallback"];
  permissions: CanProps["performs"];
}

const RouteWithPermission = ({
  children,
  permissions: hasPermissions,
  fallback,
}: RouteWithPermissionProps) => (
  <Can performs={hasPermissions} fallback={fallback}>
    {children}
  </Can>
);

export const routeFallback = (
  <div
    style={{
      height: "50vh",
      display: "flex",
      alignItems: "flex-end",
      justifyContent: " center",
    }}
  >
    <LoadingBuzz />
  </div>
);

const Router = (): JSX.Element => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  return (
    <BreadcrumbProvider>
      <Suspense fallback={routeFallback}>
        <Can
          performs={[
            PERMISSION_GROUPS.RESTRICTION_ADMIN,
            PERMISSION_GROUPS.RESTRICTION_MANAGER,
          ]}
          fallback={
            <Error403
              header={formatMessage({ id: "UNAUTHORIZED_PAGE.TITLE" })}
              description={formatMessage({
                id: "UNAUTHORIZED_PAGE.DESCRIPTION",
              })}
              buttonText={formatMessage({ id: "UNAUTHORIZED_PAGE.ACTION" })}
              onButtonClick={() => navigate(APP_ROUTES.BEES_ONE_HOME)}
            />
          }
        >
          <Routes>
            <Route
              path={APP_ROUTES.BASENAME}
              element={
                <Suspense fallback={routeFallback}>
                  <RestrictionListPage />
                </Suspense>
              }
            />
            <Route
              path={APP_ROUTES.RESTRICT_LIST}
              element={
                <Suspense fallback={routeFallback}>
                  <RestrictionListPage />
                </Suspense>
              }
            />
            <Route
              path={APP_ROUTES.CREATE_RESTRICTION}
              element={
                <Suspense fallback={routeFallback}>
                  <NewRestrictionPage />
                </Suspense>
              }
            />
            <Route
              path={APP_ROUTES.EDIT_RESTRICTION}
              element={
                <Suspense fallback={routeFallback}>
                  <EditRestrictionPage />
                </Suspense>
              }
            />
          </Routes>
        </Can>
      </Suspense>
    </BreadcrumbProvider>
  );
};

export default Router;
