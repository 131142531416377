import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from "@apollo/client";
import { useEnvService } from "admin-portal-shared-services";
import fetch from "cross-fetch";

import { randomUUIDV4 } from "@/utils/string/stringUtils";

import {
  ORCHESTRATOR_LOWER_ENV_URL,
  ORCHESTRATOR_PROD_URL,
} from "../../../config/api";

/**
 * Get the final Orchestrator GraphQL URL
 * @param {string} country
 * @returns Orchestrator GraphQL URL
 */
export const orchestratorGraphqlUrl = (country: string): string => {
  const environment = useEnvService().getEnv().toLowerCase();
  let orchestratorUrl = ORCHESTRATOR_LOWER_ENV_URL;
  switch (environment) {
    case "qa":
      orchestratorUrl = orchestratorUrl.replace("ENV", "sit");
      break;
    case "uat":
      orchestratorUrl = orchestratorUrl.replace("ENV", environment);
      break;
    default:
      orchestratorUrl = ORCHESTRATOR_PROD_URL;
      break;
  }

  orchestratorUrl = orchestratorUrl.replace("COUNTRY", country);
  return orchestratorUrl;
};

/**
 * Generate httpLink for Apollo Client
 * @param country
 * @returns httpLink
 */
export const orchestratorHttpLink = (country: string): HttpLink => {
  const httpLink = new HttpLink({
    uri: orchestratorGraphqlUrl(country),
    fetch,
  });
  return httpLink;
};

/**
 * Custom auth middleware for Appolo client
 */
export const authMiddleware = new ApolloLink((operation, forward) => {
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      Authorization: localStorage.getItem("authHeader") || "",
      requestTraceId: randomUUIDV4(),
    },
  }));

  return forward(operation);
});

/**
 * Generate new client on every request
 * @param { string } country
 * @returns Apollo client
 */
export const getApolloClient = (
  country: string
): ApolloClient<NormalizedCacheObject> => {
  const client = new ApolloClient({
    link: authMiddleware.concat(orchestratorHttpLink(country)),
    cache: new InMemoryCache({ addTypename: false }),
  });
  return client;
};
