const SCOPES = {
  RESTRICTLIST_READ: "Antifraud.RestrictList.Read",
  RESTRICTLIST_WRITE: "Antifraud.RestrictList.Write",
  ALLCOUNTRY_WRITE: "Antifraud.AllCountry.Write",
};

const ROLES = {
  RESTRICTION_MANAGER: [SCOPES.RESTRICTLIST_READ, SCOPES.RESTRICTLIST_WRITE],
  GLOBAL_MANAGER: [SCOPES.ALLCOUNTRY_WRITE],
};

const PERMISSION_GROUPS = {
  RESTRICTION_ADMIN: [...ROLES.RESTRICTION_MANAGER, ...ROLES.GLOBAL_MANAGER],
  RESTRICTION_MANAGER: ROLES.RESTRICTION_MANAGER,
};

export default PERMISSION_GROUPS;
