import { RestrictKeysEnum } from "@/types/restriction.types";
import { create } from "zustand";
import { createJSONStorage, devtools, persist } from "zustand/middleware";

interface IFilterParams {
  country?: string;
  searchKey?: string;
  page?: number;
  pageSize?: number;
  searchType?: RestrictKeysEnum;
};

export interface IFilterStore extends IFilterParams {
  setCountry: (country: IFilterStore["country"]) => void;
  setSearchKey: (searchKey: IFilterStore["searchKey"]) => void;
  setPage: (page: IFilterStore["page"]) => void;
  setPageSize: (pageSize: IFilterStore["pageSize"]) => void;
  setSearchType: (searchType: IFilterStore["searchType"]) => void;
  setPagination: (
    page: IFilterStore["page"],
    pageSize: IFilterStore["pageSize"]
  ) => void;
}

const initialState: IFilterParams = {
  country: "",
  searchKey: "",
  page: 0,
  pageSize: 10,
  searchType: RestrictKeysEnum.DEVICE_ID,
};

const devtoolsOptions = {
  name: "Antifraud - Restriction Filter",
  anonymousActionType: "useFilterStore",
  enabled: localStorage.getItem("devtools") === "true",
};

const persistOptions = {
  name: "restrictionFilterValues",
  storage: createJSONStorage(() => localStorage),
};

export const useFilterStore = create<IFilterStore>()(
  persist(
    devtools(
      (set) => ({
        ...initialState,
        setCountry: (country) => set({ country }),
        setSearchKey: (searchKey) => set({ searchKey }),
        setPage: (page) => set({ page }),
        setPageSize: (pageSize) => set({ pageSize }),
        setSearchType: (searchType) => set({ searchType }),
        setPagination: (page, pageSize) => set({ page, pageSize }),
      }),
      devtoolsOptions
    ),
    persistOptions
  )
);
