import { Container } from "@/components/Grid/Grid.styles";
import useSideBar from "@/hooks/useSideBar/useSideBar";
import Router from "@/Router";
import { SideBar } from "./AppBar.styles";

const AppBar = (): React.JSX.Element => {
  useSideBar();

  return (
    <SideBar>
      <Container
        style={{
          rowGap: "1rem",
          display: "block",
          paddingTop: "24px",
        }}
        type="fluid"
        sidebar
      >
        <Router />
      </Container>
    </SideBar>
  );
};

export default AppBar;
