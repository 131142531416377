import { Breadcrumb } from '@hexa-ui/components';
import React, { createContext, useContext, useState } from 'react';

export interface BreadcrumbItem {
  name: string;
  active?: boolean;
  href?: string;
}

export type BreadcrumbProps = {
  items: BreadcrumbItem[];
  setItems: (items: BreadcrumbItem[]) => void;
};

const defaultInitialValue: BreadcrumbProps = {
  items: [],
  setItems: () => { },
};

export const BreadcrumbContext = createContext(defaultInitialValue);
export const useBreadcrumbContext = () => useContext(BreadcrumbContext);

export default function BreadcrumbProvider({
  children,
}: React.PropsWithChildren<object>) {
  const [breadcrumbItems, setBreadcrumbItems] = useState<BreadcrumbItem[]>([]);

  return (
    <BreadcrumbContext.Provider
      value={{ items: breadcrumbItems, setItems: setBreadcrumbItems }}
    >
      <Breadcrumb.Root>
        <Breadcrumb.HomeItem href="/antifraud" />
        {breadcrumbItems.map((item) => (
          <Breadcrumb.Item
            key={item.name}
            href={item.href}
            isCurrentPage={item.active}
          >
            {item.name}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb.Root>
      {children}
    </BreadcrumbContext.Provider>
  );
}
