import APP_ROUTES from "@/config/routes";
import { useSidebar } from "admin-portal-shared-services";
import { useIntl } from "react-intl";
import { AddHomeIcon } from "./sidebarUtils";

const useAppBar = (): void => {
  const { formatMessage } = useIntl();

  useSidebar({
    items: [
      {
        id: "1",
        title: formatMessage({ id: "RESTRICTION_ATTRIBUTES.TITLE" }),
        icon: AddHomeIcon,
        path: APP_ROUTES.RESTRICT_LIST,
      },
    ],
    utils: [],
  });
};

export default useAppBar;
